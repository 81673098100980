$(document).ready(function () {

    let modalBack = $('.js_theme');
    modalBack.on('click', function () {
        $(this).addClass('hidden');
        $('.js_modal').addClass('hidden');
    });
    $('.js_theme_filt').on('click', function () {
        $(this).addClass('hidden');
        $('.js_modal').addClass('hidden');
    });

    let $headerTop        = $('.js_header_top'),
        headerMainOffset = $headerTop.height();

    $(window).resize(function() {
        headerMainOffset = $headerTop.height();
        $('.slider_picture').slick('setPosition');
    });

    $(window).scroll(function() {
        if ($(window).scrollTop() >= headerMainOffset) {
            $headerTop.addClass('fixed_header');
        } else {
            $headerTop.removeClass('fixed_header');
        }
    });

    let cookieOpt = {
        'path': '/'
    };
    // range.js
    $(".js_slider-range").slider({
        range: true,
        min: $("input[name='price-min']").data('minv'),
        max: $("input[name='price-max']").data('maxv'),
        //values: [ 500, 12000 ],
        slide: function (event, ui) {

            $("input[name='price-min']").val(ui.values[0]);
            $("input[name='price-max']").val(ui.values[1]);
        }
    });

    $(".js_slider-range").slider("values", 0, $("input[name='price-min']").val());
    $(".js_slider-range").slider("values", 1, $("input[name='price-max']").val());


    $("input[name='price-min']").on('change', function () {
        $(".js_slider-range").slider("values", 0, $(this).val());
    });

    $("input[name='price-max']").on('change', function () {
        $(".js_slider-range").slider("values", 1, $(this).val());
    });

    //$( "input[name='price-min']" ).val($( ".js_slider-range" ).slider( "values", 0 ));
    //$( "input[name='price-max']" ).val($(".js_slider-range" ).slider( "values", 1 ));

    $('.price__sbm').on('click', function () {
        setCookie('min_price', $("input[name='price-min']").val(),cookieOpt);
        setCookie('max_price', $("input[name='price-max']").val(),cookieOpt);

        setCookie('category_id', $("input[name='price-max']").data('category-id'),cookieOpt);

        location.reload();
    });

    // * отключает все чекбоксі (фильтр)
    $('.js_disable_checkboxes').click(function () {
        $('input:checked').prop('checked', false);
        $('.js_checkbox').attr('src', 'images/unchecked.svg');
        $('.js_close').addClass('hidden');

        deleteCookie('min_price');
        deleteCookie('max_price');
        deleteCookie('category_id');


        location.href = $(this).data('href');
        return false;

    });

    function deleteCookie(name) {
        setCookie(name, "", {
            'max-age': -1,
            'path' : '/'
        })
    }

    function setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }


    // ajaxes.js
    if ($('#review').length) {
        $('#review').load('index.php?route=product/product/review&product_id=' + $('#review').data('product-id'));
    }

    $('.js_category-more').on('click', function () {
        var obj = $(this);

        var text = obj.html();
        var page = parseInt(obj.data('page')) + 1;
        var urlPagination = window.location.href/*.split('?')[0]*/;

        var search = '';
        if (obj.data('search')) {
            search = '&search=' + obj.data('search');
        }

        $.ajax({
                url: urlPagination,
                method: 'GET',
                data: 'page=' + page + search,
                cache: false,
                dataType: 'json'
            })
            .done(function (response) {
                $('.filter__content').append(response.products);
                if (response.last) obj.remove();
                else {
                    obj.data('page', page);
                }
            });
    });



    // * Добавление товара в избранное
    // if($('div').is('.product__favorite')){
    //     $('.product__favorite').click(function(e){
    //         e.preventDefault();
    //         // TODO получение ид товара
    //         let id = 1;
    //         $.ajax({
    //             url: "http://nutrix.com.ua/path/to/file.php", // * path to file handler
    //             type: "POST",
    //             data: {
    //                 id: id
    //             },
    //             success: function(data){
    //                 data = parseInt(data);  // ! if handler return is integer value
    //                 if(data){
    //                     // heart has a static green color
    //                 } else{
    //                     // open popap with error or something
    //                 }
    //             },
    //             error: function(xhr, status, error) {
    //                 alert(xhr.responseText + '|\n' + status + '|\n' +error);
    //                 event.preventDefault();
    //            }
    //         });
    //     });
    // }



    // curt.js
    checkCurt();

    let minus = $('.js_calc-minus'),
        plus = $('.js_calc-plus'),
        delete_prod = $('.js_delete_prod'),
        cart = $('.js_open-cart');


    // //  !! watching on change of prices
    // $('.curt__price .price span').bind('DOMSubtreeModified', function(){
    //     watchChange();
    //     return false;
    //     let newValue = $(this).html(),
    //         sum = 0;
    //     newValue = newValue.split(" грн");
    //     newValue = newValue[0];
    //     newValue = newValue.split(" ");
    //     newValue = newValue[0] + newValue[1];
    //     newValue = Number(newValue);
    //     sum += newValue;
    //     console.log(sum);
    // });


    $('.curt__products').each(function (index, elem) {

        let value = $(this).find('.prices span').eq(1).html();
        value = value.split(" ");

        let quantity = $(this).find('.js_quant').html();
        value = value[0];
        let sum = (value, quantity) => value * quantity;
        $(this).find('.curt__price .price span').html(spaces(sum(value, quantity)) + " грн");
    });


    watchChange();

    // *open popap
    cart.click(function () {
        let cookie_lang = getCookie('language');
        let lang = 'ua';
        if (cookie_lang === 'ru') {
            lang = 'ru';
        }
        $.ajax({
            url: 'index.php?route=checkout/cart/index&language=' + window.opencart.language,
            type: 'POST',
            cache: false,
            dataType: "json"
        }).
        done(function (html) {
            if(html.success && html.content) {
                $('.cart-content').html(html.content);
                $('.modal__curt').removeClass('hidden');
                modalBack.removeClass('hidden');
            }
        }).
        fail(function (error) {
            console.log(error)
        })
        ;

        return false;

    });

    function update(quantity) {
        $.ajax({
            url: 'index.php?route=checkout/cart/edit&language=' + window.opencart.language,
            type: 'post',
            data: quantity,
            dataType: 'json',
        })
            .done(function(html) {
                if(html.success && html.content) {
                    $('.cart-content').html(html.content);
                    $('.shop__quant').html(html.count);
                }
            });
    }




    // *count quantity
    //minus = $('.js_calc-minus'),
    //plus = $('.js_calc-plus'),

    $('body').on('click','.js_calc-minus', function () {
        let inputHidden = $(this).closest('tr').find('[type="hidden"]');
        let tdVisible = $(this).closest('tr').find('.js_quant');
        let currentQ = inputHidden.val();

        if(currentQ > 1) {
            tdVisible.html(--currentQ);
        }

        update(inputHidden.attr('name') + "=" + currentQ);

        return false;

    });
    $('body').on('click','.js_calc-plus', function () {
        let inputHidden = $(this).closest('tr').find('[type="hidden"]');
        let tdVisible = $(this).closest('tr').find('.js_quant');
        let currentQ = inputHidden.val();

        //tdVisible.html(++currentQ);

        update(inputHidden.attr('name') + "=" + (++currentQ));

        return false;
    });

    // *delete product
    $('body').on('click','.js_delete_prod',function (e) {

        $.ajax({
            url: 'index.php?route=checkout/cart/remove',
            type: 'post',
            data: {'key': $(this).data('cart-id'), 'language': window.opencart.language},
            dataType: 'json'
        })
            .done(function(html) {
                if(html.success && html.content) {
                    $('.cart-content').html(html.content);
                    $('.shop__quant').html(html.count);
                }
            });

        return false;

        /*$(this).closest('.curt__products').addClass('hidden');
        $(this).parent().find('.price span').html('0 грн');

        watchChange();

        checkCurt();*/
    });

    // !Купити в один клік - открытие модального окна
    $(document).on('submit', '.one_click', function (e) {
        var lang ='ru';
        let form = $(this),
            $phoneField = $(this).find('input[type="tel"]'),
            phone = $phoneField.val(),
            $btn = $(this).find('oneclick-order__btn');

        $(this).removeClass('form_error');

        if ((phone.indexOf("_") != -1) || (phone == '')) {
            $(this).addClass('form_error');
        } else {
            $.ajax({
                url: '/index.php?route=checkout/confirm' + "&oneclick=1&lang=",
                type: 'POST',
                data: form.serialize(),
                dataType: "json",
                beforeSend: function() {
                    if ($('.preloader').length > 0) {
                        $('.preloader').fadeIn();
                    }
                    $btn.addClass('disabled');
                }
            }).
            done(function (html) {
                if (html.status == "3") {
                     if(html.lang==='ua'){
                         html.lang = '';
                     }
                    window.location.href=html.lang+'/purchase-one-click';
                    /*$('.js_modal_thx').removeClass('hidden');
                    modalBack.removeClass('hidden');
                    $phoneField.val('');*/
                }
                if ($('.preloader').length > 0) {
                    setTimeout(function() {
                        $('.preloader').fadeOut();
                    }, 1000);
                }
            }).
            fail(function (error) {
                console.log(error);
                if ($('.preloader').length > 0) {
                    setTimeout(function() {
                        $('.preloader').fadeOut();
                    }, 1000);
                }
            });
        }

        return false;

    });

    $('#button-coupon').on('click', function() {
        if($('.promo__error').hasClass('hidden')) {
            $('.promo__error').addClass('hidden');
        }
        $.ajax({
            url: 'index.php?route=extension/total/coupon/coupon',
            type: 'post',
            data: 'coupon=' + encodeURIComponent($('input[name=\'coupon\']').val()),
            dataType: 'json',
            success: function(json) {
                if (json['redirect']) {
                    location = json['redirect'];
                }
                else {
                    $('.promo__error').removeClass('hidden');
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                //alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });

        return false;
    });

    var minLengthSel2 = 3;
    var select_text_ua = {
      'enter_city': 'Введіть місто',
      'enter_letters': function(count = 3) {
        return "Будь ласка, почніть вводити перші "+count+" символи міста";
      },
      'not_found': 'Нічого не знайдено',
      'searching': 'Йде пошук'
    };
    var select_text_ru = {
      'enter_city': 'Введите город',
      'enter_letters': function(count = 3) {
        return "Пожалуйста, начните вводить первые "+count+" символа города";
      },
      'not_found': 'Ничего не найдено',
      'searching': 'Идёт поиск'
    };

    if (language_id == 2) {
      var select_text = select_text_ru;
    } else if (language_id == 3) {
      var select_text = select_text_ua;
    }

    $('#np_city_1, #np_city_2').select2({
        minimumInputLength: minLengthSel2,
        placeholder: select_text.enter_city,
        multiple: false,
        quietMillis: 350,
        language: {
            inputTooShort: function () {
                return select_text.enter_letters(minLengthSel2);
            },
            noResults: function(){
                return select_text.not_found;
            },
            searching: function(){
                return select_text.searching;
            }
        },
        ajax: {
            type: "POST",
            dataType: "json",
            url: "https://api.novaposhta.ua/v2.0/json/",
            data: function (params) {
               var query =  JSON.stringify({
                    modelName: "Address",
                    calledMethod: "getCities",
                    apiKey: "667a8a878f0a5923894dc95ee5e9f3f9",
                    methodProperties: {
                       FindByString: params.term
                   }
                });
                return query;
            },
            headers: {
                "Content-Type": "application/json"
            },
            processResults: function(data,index) {
                const results = [];
                for (var i = 0; i < data.data.length; i++) {
                    if (language_id == 2) {
                      results.push({
                                   id: data.data[i].DescriptionRu,
                                   text: data.data[i].DescriptionRu
                               });
                    } else {
                      results.push({
                                   id: data.data[i].Description,
                                   text: data.data[i].Description
                               });
                    }
                }
                return {
                    results:results
                };
            }
        },
    });

    $('#form-checkout').on('submit', function () {
        var $form = $(this);
        var formValid = true;
        var success_url = $form.data('success-url');
        var language_code = $form.data('language-code');
        var $btn = $form.find('.check__sbm');

        formValid = formValidate($(this));

        var $deliveryWay = $form.find('.js_check_delivery:checked');
        var $paymentWay = $form.find('.js_check_payment:checked');
        var $courierAddress = $('.js_courier_address');
        var $npFlatAddr = $('.js_np_flat_addr');

        $courierAddress.removeClass('field_error');
        $npFlatAddr.removeClass('field_error');

        if ($deliveryWay.length) {
            $('.js_check_delivery').each(function (i, elem) {
                $(elem).parent().removeClass('label_invalid_radio');
            });

            if ($deliveryWay.attr('value') == 'courier' && $courierAddress.val() == '') {
                $courierAddress.addClass('field_error');
                formValid = false;
            }
            if ($deliveryWay.attr('value') == 'novaja-pochta-addr' && $npFlatAddr.val() == '') {
                $npFlatAddr.addClass('field_error');
                formValid = false;
            }
        } else {
            $('.js_check_delivery').each(function(i, elem) {
                $(elem).parent().addClass('label_invalid_radio');
            });
            formValid = false;
        }

        if ($paymentWay.length) {
            $('.js_check_payment').each(function (i, elem) {
                $(elem).parent().removeClass('label_invalid_radio');
            });
        } else {
            $('.js_check_payment').each(function (i, elem) {
                $(elem).parent().addClass('label_invalid_radio');
            });
            formValid = false;
        }

        if (!formValid) {
            return false;
        }

        $('.checkout-form-require-error').hide();
        var error = false;

        if(!error) {
            $.ajax({
                url: '/index.php?route=checkout/confirm',
                type: 'POST',
                data: $form.serialize() + '&language_code=' + language_code,
                dataType: "json",
                beforeSend: function() {
                    if ($('.preloader').length > 0) {
                        $('.preloader').fadeIn();
                    }
                    $btn.addClass('disabled');
                }
            }).
            done(function (html) {
                if(html.status == "3") {
                    if(html.form) {
                        let paymentForm = $(html.form);
                        $form.after(paymentForm);
                        paymentForm.submit();
                    }
                    else {
                        location.href = success_url;
                    }
                }
                if ($('.preloader').length > 0) {
                    setTimeout(function() {
                        $('.preloader').fadeOut();
                    }, 1000);
                }
            }).
            fail(function (error) {
                console.log(error);
                if ($('.preloader').length > 0) {
                    setTimeout(function() {
                        $('.preloader').fadeOut();
                    }, 1000);
                }
            })
            ;
        }


        return false;
    });

    // *функция подсчета общей цены
    function watchChange() {
        let sum = 0;
        $('.curt__price .price span').each(function (ind, el) {
            let newValue = $(this).html();
            newValue = newValue.split(" грн");
            newValue = newValue[0];
            if (hasWhiteSpace(newValue)) {
                newValue = newValue.split(" ");
                newValue = newValue[0] + newValue[1];
            }
            newValue = Number(newValue);
            sum += newValue;
        });
        $('.curt__summ span').html(spaces(sum) + " грн")
    }


    // js coming to checkout page
    $('body').on('click','.js_checkout',function () {
        document.location.href = $(this).data('href');
    });


    // !do not touch
    function pCounter(counter) {
        counter++;
        return counter;
    }

    function mCounter(counter) {
        counter--;
        return counter;
    }

    function spaces(n) {
        n += "";
        n = new Array(4 - n.length % 3).join("U") + n;
        return n.replace(/([0-9U]{3})/g, "$1 ").replace(/U/g, "");
    }

    function hasWhiteSpace(s) {
        return s.indexOf(' ') >= 0;
    }


    // * проверка корзины на отсутствие товаров
    function checkCurt() {
        let childs = $('.curt__products__buy').children();
        let len = childs.length;
        // len--;

        $.each(childs, function (i, el) {
            // console.log(el);
            if ($(el).hasClass('hidden')) {
                len--;
            }
        });
        // console.log(len);
        if (len > 0) {

        } else {
            $('.curt__products_empty').removeClass('hidden');
        }
    }



    // rating.js
    let star = $('.form__star_rate');
    star.click(function () {
        let index = $(this).index();
        star.removeClass('js_rate-active');
        star.addClass('js_rate-deactive');
        let num = index;
        num++;
        $('input[name="rating"]').val(num);
        // $('.success__rating-text').text('( ' + (num) + ' )');
        for (let i = 0; i <= index; i++) {
            star.eq(i).removeClass('js_rate-deactive');
            star.eq(i).addClass('js_rate-active');
        }
    });



    // hoveritems.js
    // * open menu adaptive (burger)
    $('.js_open-menu').click(function () {
        modalBack.removeClass('hidden');
        $('.tel__menu').removeClass('hidden');
    });
    $('.js_close-head-menu').click(function () {
        $('.tel__menu').addClass('hidden');
        modalBack.addClass('hidden');
    });

    $('.menu .menu__item > a').click(function(e){
        if($(window).width() > 767 && $(window).width() < 992){
            if(!$(this).hasClass('js_tablet_clicked')){
                e.preventDefault();
                $('.menu .menu__item > a').removeClass('js_tablet_clicked');
                $(this).addClass('js_tablet_clicked');
            }
        }
    });

    // *open filter menu (adaptive)
    var filter = $('.js-filter-product');
    if (filter) {
        $('.js_filter_open').click(function () {
            $('.js-filter-product').fadeIn(400);
            $('.js_theme_filt').removeClass('hidden');
        });
        $('.js_filter_close').on('click', function() {
            filter.fadeOut(200);
            $('.js_theme_filt').addClass('hidden');
        });
        $('.js_theme_filt').on('click', function() {
            filter.fadeOut(200);
        });
    }

    $('.orders__item').last().css({
        "border-bottom": "1px solid #31ada5"
    });


    // hover in head (open menu)
    let show = $('.js_showMainDD'),
        dd   = $('.js_mainDD'),
        ul = $('.header__menu ul');
    if ($(window).width() >= '500'){
        let idOfPopap = null;

        $('.js_showMainDD').mouseover(function() {
            idOfPopap = $(this).attr("id");
            console.log(idOfPopap);
            $('#submenu_' + idOfPopap).removeClass('hidden');
        });

        $('.js_mainDD').mouseover(function(){
            $('#submenu_' + idOfPopap).removeClass('hidden');
        });

        $('.header__menu , .js_mainDD').mouseleave(function() {
           /* setTimeout(function () {*/
                dd.addClass('hidden');

            /*},850)*/
        });
    } else {
        show.click(function(){
            if(dd.hasClass('hidden')){
                dd.removeClass('hidden');
            }
            else{
                dd.addClass('hidden');
            }
        });
    }
    // header stick menu onClick show drop down
    let el = $('.main__search'),
        ddHead = $('.main__dropDown'),
        ddHead_Item = $('.main__DD-item'),
        input = $('.js-search-form__input'),
        list = $('.main__DD-list')
    ;


    $('.main__DD-list').on('click','.js-searcj-item', function() {
        location.href=$(this).data('href');
    });

    $('.js-search-in-mobile').on('keyup',function(e) {
        if (e.keyCode == 13) {

            var form = $(this).closest('form');
            var url = form.attr('action');
            url += "&search=" + form.find('[name="search"]').val();
            location.href = url;
            return false;
        }
    });


    input.closest('form').submit(function() {
        return false;
    });

    input.on('keyup', function(e) {

        $(list).html("");

        if (e.keyCode == 13) {

            var form = input.closest('form');
            var url = form.attr('action');
            url += "&search=" + form.find('[name="search"]').val();
            location.href = url;
            return false;
        }
        else {
            if($(this).val() != '' && $(this).val().length > 2) {

                $.ajax({
                    url: 'index.php?route=product/search',
                    type: 'GET',
                    data : 'search='+$(this).val() + '&is_ajax=1',
                    dataType: "json"
                }).
                done(function (html) {
                    if(html.success && html.content) {
                        $(list).html(html.content);
                        $(list).addClass('search-form__result-list_active')
                    }
                }).
                fail(function (error) {
                    console.log(error)
                })
                ;

            } else {
                $(list).removeClass('search-form__result-list_active')
            }
        }
    })



    el.click(function () {
        if (ddHead.hasClass('hidden'))
            ddHead.removeClass('hidden');
        else
            ddHead.addClass('hidden');

        $(document).mouseup(function (e) {
            if (ddHead.has(e.target).length === 0) {
                ddHead.addClass('hidden');
            }
        });
    });
    ddHead_Item.click(function () {
        let value = $(this).find('.main__DD-name').text();
        value = $.trim(value);
        $('.main__input').val(value);
    });



    // *open all  orders --- ! order.php ! ---
    let order_more = $('.js_order-more');

    order_more.click(function (e) {
        e.preventDefault();
        if ($(this).hasClass('open')) {
            $(this).closest('.orders__item').find('.orders__more').removeClass('hidden');
            $(this).removeClass('open');
            $(this).addClass('close');
        } else {
            $(this).closest('.orders__item').find('.orders__more').addClass('hidden');
            $(this).addClass('open');
            $(this).removeClass('close');
        }


    });



    // hover and delete margin in product-complex sectyion ---- main page
    // !костыль
    if (($('section').is('.best')) && ($('section').is('.complex')) || ($('section').is('.prod'))) {
        // let defaultMarg = $('.js_unhover').css("margin-top");
        // $('.product').mouseover(function () {
        //     if ($(window).width() >= '1100') {
        //         $('.js_unhover').css({
        //             "margin-top": "-144px"
        //         });
        //     }
        // });
        // $('.product').mouseleave(function () {
        //     $('.js_unhover').css({
        //         "margin-top": defaultMarg
        //     });
        // });



    } else {

    }
    if (($('section').is('.best')) && ($('section').is('.complex'))) {
        // cancel link logo on main page
        $('.main__logo a').click(function (e) {
            e.preventDefault();
        });
    }



    // * показывает только первые 5 комментариев
    /*if($('div').is('.have_coment_comments')){
        $('.have_coment_comments:lt(5)').removeClass('hidden');
    }*/

    $('body').on('click', '.js_showProduct', function () {

        $.ajax({
                url: 'index.php?route=product/product/review&product_id=' + $('#review').data('product-id') + "&page=2",
                type: 'get'
            })
            .done(html => {
                if (html) {
                    $('#review').append(html);
                    $('.js_showProduct').hide();
                }

            })
            .fail(error => {
                console.log(error);
            });
        return false;
    });



    // * показывает больше товаров
    $('.js_category-more').click(function () {
        $('.product').removeClass('hidden');
    });





    // ! NEED DELETE LATER
    if ($('.js_contact__form')) {

        $('.js_contact__form').on('submit', function () {
            var form = $(this);
            if (formValidate(form)) {
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    data: form.serialize(),
                    url: form.attr('action'),
                    success: function (json) {
                        if (json == 'success') {
                            console.log(json);
                            form.addClass('hidden');
                            $('.form__thx').removeClass('hidden');
                        }
                    }
                });
            } else {
                return false;
            }

            return false;
        });
    }




    // $('.js_fav').click(function (e) {
    //     e.preventDefault();
    //     if ($(this).hasClass('product__favorite')) {
    //         if ($(this).hasClass('hoverNoRevert')) {
    //             $(this).removeClass('hoverNoRevert');
    //             $(this).find('svg').addClass('svg-fav_active');
    //         } else {
    //             $(this).addClass('hoverNoRevert');
    //             $(this).find('svg').removeClass('svg-fav_active');

    //         }
    //     } else {
    //         $(this).find('svg').addClass('svg-fav_active');
    //     }
    // });

    function formValidate(form) {
        var valid = form.find('.js_validate');
        var validFlag = true;

        valid.each(function (i, elem) {
            $(elem).removeClass('field_error');

            if ($(elem).attr("type") == "text" || $(elem).prop('tagName') == 'TEXTAREA') {
                if (!validText(elem) && validFlag) {
                    validFlag = false;
                }
            }
            if ($(elem).attr("type") == "email") {
                if (!validMail(elem) && validFlag) {
                    validFlag = false;
                }
            }
            if ($(elem).attr("type") == "tel") {
                if (!validTel(elem) && validFlag) {
                    validFlag = false;
                }
            }
            if ($(elem).attr("type") == "password") {
                if (!validPass(elem) && validFlag) {
                    validFlag = false;
                }
            }
        });

        return validFlag;
    }

    function validText(el) {
        let val = $(el).val();
        if ((val == '')) {
            $(el).addClass('field_error');
            return false;
        }
        return true;
    }

    function validMail(el) {
        let reg = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/,
            mail = $(el).val(),
            result = mail.match(reg);
        if (!result) {
            $(el).addClass('field_error');
            return false;
        }
        return true;
    }

    function validTel(el) {
        let phone = $(el).val();
        if ((phone.indexOf("_") != -1) || (phone == '')) {
            $(el).addClass('field_error');
            return false;
        }
        return true;
    }

    function validPass(el) {
        let pass = $(el).val();
        if ((pass == '')) {
            $(el).addClass('field_error');
            return false;
        }
        return true;
    }

    $('.js_not_avail__form').submit(function (e) {
        e.preventDefault();
        var formNotAvail = $(this);
        formNotAvail.removeClass('form_error');
        if (!formValidate(formNotAvail)) {
            formNotAvail.addClass('form_error');
        }
        else {
            $('.not-avail-success').slideUp();
            $.ajax({
                type: 'POST',
                dataType: 'json',
                data: formNotAvail.serialize(),
                url: formNotAvail.attr('action'),
                success: function (json) {
                    if (json.status == 'success') {
                        $('[name="email"]').removeClass('hidden');
                        $('.not-avail-success').slideDown();
                    }
                }
            });
        }
    });

    // Subscribe
    var subscribeForm = $('.js_subscribe__form');
    if (subscribeForm) {
        subscribeForm.on('submit', function () {
            if (formValidate($(this))) {
                $('.js_modal_subscribe').removeClass('hidden');
                modalBack.removeClass('hidden');
                $(this).find('input[type="email"]').val('');
            }
            return false;
        });
    }

    //Registration/authorization

    let form1 = $('#form1'),
        form2 = $('#form2');

    if ($('#form1')) {

        form1.submit(function (e) {
            if (!formValidate($(this))) {
                e.preventDefault();
            }
        });
    }


    if ($('#form2')) {
        $('#form2, .js_form_email').submit(function (e) {

            let formEmail = $(this);

            e.preventDefault();
            let valid = $(this).find('.js_validate');
            valid.each(function (i, elem) {
                $(elem).removeClass('field_error');

                if ($(elem).attr("type") == "email") {
                    validMail(elem, e);
                }
                if ($(elem).attr("type") == "tel") {
                    validTel(elem, e);
                }
            });

            if($(this).find('.field_error').length == 0) {
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    data: form2.serialize(),
                    url: form2.attr('action'),
                    success: function (json) {
                        if (json == 'success') {
                            $('.email_result').removeClass('hidden');
                            formEmail.find('input').val('');
                        }
                    }
                });
            }

            return false;
        });
    }



    // filtr.js
    if ($('section').is('.category')) {
        let item = $('.js_hide'),
            close = $('.js_close');

        // item.addClass('hidden');
        $('.js_moreHide').addClass('hidden');


        $('.js_iconPlusMinus').click(function () {
            if ($(this).parent().parent().find('.js_hide').hasClass('hidden')) {
                $(this).parent().parent().find('.js_hide').removeClass('hidden');
                $(this).attr("src", "catalog/view/theme/nutrix/images/minus.svg");
            } else {
                $(this).parent().parent().find('.js_hide').addClass('hidden');
                $(this).attr("src", "catalog/view/theme/nutrix/images/plus.svg");
            }
        });

        $('.js_showFiltr').click(function () {
            if ($(this).parent().parent().find('.js_hide').hasClass('hidden')) {
                $(this).parent().parent().find('.js_hide').removeClass('hidden');
                $(this).parent().find('img').attr("src", "catalog/view/theme/nutrix/images/minus.svg");
            } else {
                $(this).parent().parent().find('.js_hide').addClass('hidden');
                $(this).parent().find('img').attr("src", "catalog/view/theme/nutrix/images/plus.svg");
            }
        });

        $('body').on('change', '.js_watch', function () {
            if ($(this).prop('checked')) {
                $(this).siblings('.js_checkbox').attr("src", "catalog/view/theme/nutrix/images/checked.png");
                $(this).siblings('.js_close').removeClass('hidden');
            } else {
                $(this).siblings('.js_checkbox').attr("src", "catalog/view/theme/nutrix/images/unchecked.svg");
                $(this).siblings('.js_close').addClass('hidden');
            }
        });

        close.click(function () {
            $(this).addClass('hidden');
        });



        // * ajax send prices

    }

    /*header lang*/
    $('.js-h-lang').click(function(){
        $(this).toggleClass('open');
    });
    $(document).mouseup(function (e){
        var curTarget = $('.js-h-lang');
        if (!curTarget.is(e.target) && curTarget.has(e.target).length === 0) {
            curTarget.removeClass('open');
        }
    });
    /*header lang*/



    // dropDownS.js
/*
    if ($('section').is('.category')) {
*/
    if ($('section').is('.catP') || $('section').is('.category')) {
        let product = $('.js_unHover'),
            dd = $('.js_dropDown'),
            blockDD = $('.js_show-dropDown'),
            item = $('.js_dropDown-item'),
            menu = $('.js_mainDD');

        dd.click(function () {
            if (blockDD.hasClass('hidden')) {
                blockDD.removeClass('hidden');
            } else {
                blockDD.addClass('hidden');
            }
        });

        item.click(function () {
            blockDD.addClass('hidden');
            let value = $(this).text();

            dd.find('p').text(value);

            location.href = $(this).data('href');
        });

        $(document).mouseup(function (e) {
            var dropDown = $(blockDD);
            if (!dropDown.is(e.target) && dropDown.has(e.target).length === 0 && !dd.is(e.target) && dd.has(e.target).length === 0) {
                dropDown.addClass('hidden');
            }
        });
    }


    // modals.js

    $('body').on('click','.js_close',function () {
        $(this).closest('.js_modal').addClass('hidden');
        modalBack.addClass('hidden');
    });

    $('body').on('click', '.js_showBuyPopap', function(e) {
        e.preventDefault();
        let quantity = 1;

        let cookie_lang = getCookie('language');
        let lang = 'ua';
        if (cookie_lang === 'ru') {
          lang = 'ru';
        }

        var obj = {
            product_id : $(this).data('product-id'),
            quantity : quantity
        }

        $.ajax({
            url: 'index.php?route=checkout/cart/add&language=' + window.opencart.language,
            type: 'POST',
            data : obj,
            dataType: "json"
        }).
        done(function (html) {
            if(html.success && html.content) {
                $('.cart-content').html(html.content);

                $('.shop__quant').html(html.count);

                $('.modal__curt').removeClass('hidden');
                modalBack.removeClass('hidden');
            }
        }).
        fail(function (error) {
            console.log(error)
        });
    });



    function validate(form, notify) {
        notify.hide();

        form.find('[required]').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        });

        if (form.find('[required]').hasClass('error')) {
            notify.text('Заполните обязательные поля').show();
            return false;
        }

        return true;
    }

    function clearForm(form) {
        form.find('input:not([type="submit"]), textarea').each(function () {
            $(this).val('');
        });
    }

    // reviews
    var $addReviewBtn = $('.js_add_review'),
        $reviewForm = $('.js_form_review'),
        $closeFormBtn = $reviewForm.find('.js_close_form_review'),
        $reviewBlock = $('.js_review_block'),
        $reviewModal = $('.js_modal_review');

    function hideReviewForm() {
        $reviewForm.removeClass('show');
        $reviewBlock.removeClass('hidden');
    }

    if ($addReviewBtn) {
        $addReviewBtn.on('click', function () {
            $reviewBlock.addClass('hidden');
            $reviewForm.addClass('show');
        });

        $reviewForm.on('submit', function () {

            var form = $(this),
                notify = form.find('.notify-event-text_err');

            if (validate(form, notify)) {
                $.ajax({
                        url: form.attr('action'),
                        type: 'post',
                        data: form.serialize(),
                        dataType: 'json'
                    })
                    .done(html => {
                        if (html.success) {
                            $reviewModal.removeClass('hidden');
                            modalBack.removeClass('hidden');
                            $reviewForm.find('input[type="text"], input[type="email"], input[type="radio"], textarea').val('');
                            $reviewForm.find('.rate__star').removeClass('js_rate-active').addClass('js_rate-deactive');
                        }

                    })
                    .fail(error => {
                        console.log(error);
                    });
            }

            return false;

        });

        $closeFormBtn.on('click', function () {
            hideReviewForm();
            return false;
        })

        $reviewModal.find('.js_close_modal_review').on('click', function () {
            $reviewModal.addClass('hidden');
            modalBack.addClass('hidden');
            clearForm($reviewForm);
            hideReviewForm();
        })
    }





    // * проверка на пустую корзину
    checkCurt();



    function checkCurt() {
        let childs = $('.curt__products__buy').children();
        let len = childs.length;
        // len--;

        $.each(childs, function (i, el) {
            if ($(el).hasClass('hidden')) {
                len--;
            }
        });
        if (len > 0) {

        } else {
            $('.curt__products_empty').removeClass('hidden');
        }
    }



    // location.js
    $(document).ready(function(){
        if($('button').is('.js-go-toPage')){
            $('.js-go-toPage').click(function(){
                document.location.href = $(this).attr("name");
            });
        }
    });



    // timer.js
    var timer = new easytimer.Timer();
    timer.start({
        countdown: true,
        startValues: {
            days: $('.timer__days .day').data('days'),
            hours: $('.timer__days .hour').data('hours'),
            minutes: $('.timer__days .min').data('minutes'),
            seconds: $('.timer__days .sec').data('seconds')
        }
    });
    timer.addEventListener('secondsUpdated', function (e) {
        $('.timer__days .day').html(timer.getTimeValues().days);
        $('.timer__days .hour').html(timer.getTimeValues().hours);
        $('.timer__days .min').html(timer.getTimeValues().minutes);
        $('.timer__days .sec').html(timer.getTimeValues().seconds);
    });



    //checkout_form.js
    if (($('section').is('.check')) || ($('section').is('.profile'))) {
        let radioA = $('.js_radio-a'),
            radioB = $('.js_radio-b');

        radioA.click(function () {
            radioA.each(function (index, element) {
                $(this).find('.radio_custom').removeClass('active');
            });

            $(this).find('.radio_custom').addClass('active');
        });

        radioB.click(function () {
            radioB.each(function (index, element) {
                $(this).find('.radio_custom').removeClass('active');
            });

            $(this).find('.radio_custom').addClass('active');
        });

        $('body').on('change', '.js_checked', function () {
            if ($(this).prop('checked')) {
                $(this).parent().find('img').attr("src", "images/checked.png");
            } else {
                $(this).parent().find('img').attr("src", "images/unchecked.svg");
            }
        });


        // *! CHECKING PROMOCODE | ajax

        $('#promo').submit(function (event) {
            let code = $('#promo .promo__field').val();

            $.ajax({
                url: 'pathToFile.php',
                type: 'POST',
                data: {
                    promo: code
                },

                success: function (answer) {
                    // !if answer is numeric
                    answer = parseInt(answer);

                    if (answer) { // !true
                        $('.promo__error').addClass('hidden');
                        $('.promo__success').removeClass('hidden');
                    } else { // !false
                        $('.promo__success').addClass('hidden');
                        $('.promo__error').removeClass('hidden');
                    }
                },
                error: function (xhr, status, error) {
                    alert(xhr.responseText + '|\n' + status + '|\n' + error);
                    event.preventDefault();
                }
            });
        });
    }

    $("input[type=tel]").inputmask("+38(099) 999-99-99");

    if ($('div').is('.slider_picture')) {
        $('.slider_picture').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            adaptiveHeight: true,
            prevArrow: $('.js_slickPrev-product22'),
            nextArrow: $('.js_slickNext-product22'),
        });
    }

    if ($('div').is('.js-slick-multiple-items')) {
        $('.js-slick-multiple-items').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            prevArrow: $('.js_slickPrev-product'),
            nextArrow: $('.js_slickNext-product'),
            rows: 1,
            responsive: [{
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 501,
                    settings: {
                        rows: 2,
                        dots: true,
                        slidesToShow: 2,
                    }
                }
            ]
        });
    }

    if ($('div').is('.js-slick-watched-items')) {
        $('.js-slick-watched-items').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            prevArrow: $('.js_slickPrev-watched'),
            nextArrow: $('.js_slickNext-watched'),
            rows: 1,
            responsive: [{
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 501,
                    settings: {
                        rows: 2,
                        dots: true,
                        slidesToShow: 2,
                    }
                }
            ]
        });
    }

    if ($('div').is('.slick-company-list')) {
        $('.slick-company-list').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            dots: false,
            prevArrow: $('.js_slickPrev-company'),
            nextArrow: $('.js_slickNext-company'),
            responsive: [{
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }

    if ($('.js-prod__slider')) {
        $('.js-prod__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            prevArrow: $('.js_slickPrev-product22'),
            nextArrow: $('.js_slickNext-product22'),
            asNavFor: '.js-prod__slider-nav',
            responsive: [
                {
                    breakpoint: 501,
                    settings: {
                        dots: true
                    }
                }
            ]
        });

        $('.js-prod__slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            centerMode: true,
            centerPadding: 0,
            asNavFor: '.js-prod__slider',
            focusOnSelect: true
        })
    }
    if($('.img-in').length != 0) {
        $('.img-in').each(function(){
            $(this).zoom();
        });
    }



    $('.js_send__fav').on('click', function () {
        var product_id = $(this).data('product-id');

        let url = 'index.php?route=account/wishlist/add';
        let remove = false;

        if($(this).find('.svg-fav_active').length > 0 || $(this).hasClass('active')) {
            url = 'index.php?route=account/wishlist' + "&remove="+product_id;
            remove = true;
        }

        var elem = $(this);
        $.ajax({
            url: url,
            type: 'post',
            data: 'product_id=' + product_id,
            dataType: 'json'
        })
            .done(function (html) {
                if(html.success && !remove) {
                    elem.addClass('active');
                }
                else {
                    elem.removeClass('active');
                }
            })
            .fail(function () {
                console.log();
            });
        return false;
    });

    $('.js_product-delete').on('click',function() {
        var product_id = $(this).data('product-id');
        var elem = $(this).closest('.product__link');

        $.ajax({
            url: 'index.php?route=account/wishlist&remove='+product_id,
            type: 'get'
        })
            .done(function (html) {
                if(html.success) {
                    elem.remove();
                }
            })
            .fail(function () {
                console.log();
            });
        return false;
    });

    //
    var $np_fields = $('[name="np_city"], [name="np_department"]');
    var $np_addr_fields = $('[name="np_city_addr"], [name="np_flat_addr"]');
    $('[name="delivery"]').change(function(){
        if($(this).val() != 'novaja-pochta'){
            $np_fields.prop('disabled', true);
        } else {
            $np_fields.prop('disabled', false);
        }
        if($(this).val() != 'novaja-pochta-addr'){
            $np_addr_fields.prop('disabled', true);
        } else {
            $np_addr_fields.prop('disabled', false);
        }
    });
    $(document).ready(function(){
        if($('[name="delivery"]').val() != 'novaja-pochta'){
            $np_fields.prop('disabled', true);
        } else {
            $np_fields.prop('disabled', false);
        }
    });

    $('.desc-tabs a').click(function(e){
        e.preventDefault();
        $('.desc-tabs a').removeClass('active');
        $(this).addClass('active');
        $('.desc').removeClass('active');
        $($(this).attr('href')).addClass('active');
    });



    // A Function to Get a Cookie
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    // Select language
  var currentLanguage = getCookie('language');

  currentLanguage = currentLanguage.substring(0, currentLanguage.indexOf('-'));
  switch(currentLanguage) {
    case 'en':
      currentLanguage = "English"
      break;
    case 'ru':
      currentLanguage = "Русский"
      break;
    case 'ua':
      currentLanguage = "Українська"
      break;
  }

  $('.js-lang-select-text').text(currentLanguage);

  function redirectToLang (lang) {
      var loc = window.location;
      var path = loc.pathname;

      if(path[0] == '/') {
          path = path.slice(1);
      }
      if(path.indexOf('ru') == '0' || path.indexOf('ua') == '0' || path.indexOf('en') == '0') {
          path = path.slice(2);
      }


      if(path[0] != '/') {
          path = '/' + path;
      }

      // console.log(loc.host);
      if(lang !== 'ua') {
          loc.href = loc.protocol + '//' + loc.hostname + '/' + lang + path;
      }
      else {
          loc.href = loc.protocol + '//' + loc.hostname + path;
      }

      return false;
  }

  $('.js-select-language').on('click', function() {

      var lang = $(this).data('lang');
      redirectToLang(lang);

      return false;

  });
  // End select language

    /*fake fb icon*/
    // Facebook SDK for JavaScript inject code
    function injectFbSdkAsync(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    };

    const $fbMessangerFakeButton = $('.js-fb-messanger-button');
    $fbMessangerFakeButton.on('click', function(){
        // Fades out the fake button a bit, to indicate "loading"
        $fbMessangerFakeButton.css({ opacity: 0.4 });
        // Inject the Facebook SDK in async manner:
        injectFbSdkAsync(document, 'script', 'facebook-jssdk');

        // Callback on Facebook SDK init, that fires up the Messanger plugin
        window.fbAsyncInit = function() {
            FB.init({ xfbml: true, version: 'v8.0' });

            // Callback when Facebook SDK finish up with rendering
            FB.Event.subscribe('xfbml.render', function(){
                // Immediately toggle opening the Facebook Messanger,
                // as if the user clicked the real Messanger button.
                FB.CustomerChat.show(true);

                // Hide the fake button, so that only the real one remains!
                $fbMessangerFakeButton.css({ display: 'none' });
            });
        };
    });
    /*fake fb icon*/

    /*knowledge base*/
    if ($('.js-langWrap').length > 0) {
        $('.js-langTab').each(function(i){
            if (i > 0) {
                return;
            }
            $(this).addClass('active')
        })
        $('.js-langWrap').each(function(i){
            if (i > 0) {
                return;
            }
            $(this).css('display', 'block')
        })
    }

    $('.js-langTab').click(function(e){
        e.preventDefault()
        $('.js-langTab').removeClass('active')
        $('.js-langWrap').css('display', 'none')
        $(this).addClass('active')
        $($(this).attr('href')).css('display', 'block')
    })
    /*knowledge base*/


});
